
.list-title {
  font-size: 1.5em;
  margin: 10px 0 5px 10px;
  text-align: left;
}
.ant-list-item-meta {
  padding-left: 15px;
}
.ant-list-item.hide {
  background: #d9d9d9;
}
.ant-list-item.active {
  background: #e6f7ff;
}
.ant-form-item {
  margin-bottom: 4px;
}
.filter_title {
  margin-top: 10px;
}
.gm-style-iw-chr {
  height: 0;
  width: 0;
  position: absolute;
  right: 20px;
  top: 2px;
}
.gm-style-iw-chr button {
  height: 0 !important;
  width: 0 !important;
}
.gm-style-iw-chr button span {
  height: 18px !important;
  width: 18px !important;
  margin: 0 !important;
}
.gm-style-iw-d {
  padding-top: 12px;
}
